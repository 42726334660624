import { Document, Image, Page, Text, View, pdf } from "@react-pdf/renderer"
import { _renderLoading } from "components/SectionSliderProductCard"
import { useCollege } from "store/utils/hooks"
import { Order } from "data/types"
import { getPrettyDate } from "utils/get-pretty-date"
import getEuro from "utils/getEuro"
import { saveAs } from 'file-saver';
import { ReactNode, useState } from "react"

const bodyText = {
  marginVertical:2,
  fontSize:12
}

const spaceBetween = {
  display:'flex',
  flexDirection:'row',
  justifyContent:'space-between',
  gap:4
}

const longHead = {
  backgroundColor:'#6F1D46', 
  color:'white', 
  paddingHorizontal:24, 
  paddingVertical:5,
  fontSize:16,
  marginVertical:5
}

const RowPdf = ({first, second, style={}, firstStyle={}, secondStyle={}}:{first:ReactNode, second:ReactNode, style?:any, firstStyle?:any, secondStyle?:any})=>(<View style={{...spaceBetween, ...style}}>
<Text style={{...bodyText, ...firstStyle}}>{first}</Text>
<Text style={{...bodyText, ...secondStyle}}>{second}</Text>
</View> )


export const PdfBillDownload = ({order, children, noIcon, className="",isRefund}:{noIcon?:boolean, order:Order, children?:ReactNode, className?:string, isRefund?:boolean}) => {
    const [isLoading,setIsLoading] = useState(false)
    const college = useCollege()
    const refundSendPrice = order.refunded?.sendPrice
    const sendPrice = order?.send_price
    const discount = order?.discount
    const devolucion = order.line_items.reduce((acc,item)=>{
      acc+= item.devolution * item.price
      return acc
    },0)
    const data = isRefund?order.line_items.filter((it)=>it.devolution):order.line_items
    const bill = <Document>
    <Page size="A4">
      <View style={{borderStyle:"solid", borderColor:"#000", borderWidth:1, padding:20, margin:20}}>
        <View style={{display:'flex', flexDirection:'row', justifyContent:'space-between', alignItems:'center', borderBottom:'solid', borderBottomWidth:1, borderColor:"#000", paddingBottom:20}}>
          <View style={{width:'100%', display:'flex', flexDirection:'row', alignItems:'center'}}>
            <Image src='logo-factura.png' style={{width:200}}/>
          </View>
          <View style={{width:'100%', display: 'flex', flexDirection:'row', justifyContent: 'flex-end'}}>
            <Text style={{...bodyText}}>{college?.name}</Text>
          </View>
        </View>
        <View style={{
          marginTop:20, 
          display:'flex', 
          flexDirection:'row', 
          gap:4, 
          alignItems:'flex-start',
          justifyContent:'space-between', 
          paddingBottom:20, 
          borderBottomStyle:'solid', 
          borderBottomWidth:1, 
          borderBottomColor:'#000'}}>
          <View style={{width:'100%'}}>
            {/* <Text style={{fontWeight:'bold',...bodyText}}>Factura a:</Text> */}
            {/* <View> */}
              <Text style={{...bodyText}}>CLIENTE</Text>
              <Text style={{fontWeight:'bold',...bodyText}}>{order.billing?.name} {order.billing?.last_name}</Text>
              {/* <Text style={{...bodyText}}>{order.billing?.phone}</Text> */}
              <Text style={{...bodyText}}>{order.billing?.address}, {order.billing?.city},</Text>
              <Text style={{...bodyText}}>CP: {order.billing?.potal_code}, {order.billing?.province}</Text>
              {/* <Text style={{...bodyText}}>{order.billing.email}</Text> */}
            {/* </View> */}
          </View>
          <View style={{width:'100%', display:'flex', gap:4, flexDirection:'row', justifyContent:'flex-end'}}>
              <View style={{display:'flex', flexDirection:'column', alignItems:'flex-end', width:'100%'}}>
                {isRefund && <Text style={{...bodyText}}>Factura Rectificativa: </Text>}
                <Text style={{...bodyText}}>Factura: </Text>
                <Text style={{...bodyText}}>Fecha: </Text>
              </View>
              <View style={{display:'flex', flexDirection:'column', alignItems:'flex-end', width:'100%'}}>
                {isRefund && <Text style={{...bodyText}}>{order.refunded_bill_number}</Text>}
                <Text style={{...bodyText}}>{order.bill_number}</Text>
                <Text style={{...bodyText}}>{getPrettyDate(isRefund?order.refunded_at:order.created_at)}</Text>
              </View>
          </View>
        </View>
        <View style={{marginTop:20}}>
          <View style={{display:'flex', flexDirection:'row', ...bodyText, borderBottomWidth:1, paddingBottom:5, marginBottom:5}}>
            <View style={{flex:3}}>
              <Text>Nombre</Text>
            </View>
            <Text style={{flex:1, textAlign:'left'}}>Precio</Text>
            <Text style={{flex:1, textAlign:'center'}}>Cantidad</Text>
            <Text style={{flex:1, textAlign:'right'}}>Total</Text>
          </View>
          {
            data?.map(({name, product, price, product_id, amount, total, devolution}, idx)=>{
              const refund = devolution
              return <View style={[{display:'flex', flexDirection:'row', ...bodyText, paddingVertical:5},idx!==data.length-1?{borderBottomWidth:1,borderBottomStyle:"dashed"}:{}]}>
              <View style={{flex:3}}>
                <Text>{product?.composed_name || name}</Text>
              </View>
              <Text style={{flex:1, textAlign:'left'}}>{getEuro(price)}</Text>
              <View style={{flex:1, textAlign:'center'}}>
                {isRefund && refund 
                ?<Text>  -{devolution}</Text>
                :<Text>{amount}</Text>
                }
              </View>
              <View style={{flex:1, textAlign:'right'}}>
                  {isRefund && refund ? (
                    <Text>  {getEuro(- devolution * Number(price))}</Text> 
                ) : (
                  <Text>{getEuro(Number(total))}</Text>
                )}
              </View>
            </View>
            })
          }
        </View>
        <View style={{paddingTop:20, width:'50%', marginLeft:'auto',...bodyText}}>
          <View>
            <View style={{...spaceBetween}}>
              <Text style={{...bodyText}}>Envío: </Text>
              <Text style={{...bodyText}}>{getEuro(
                  isRefund ? (refundSendPrice *-1) : sendPrice
                )}</Text>
            </View>
            <View style={{...spaceBetween}}>
              <Text style={{...bodyText}}>Descuento: </Text>
              <Text style={{...bodyText}}>{getEuro(
                  isRefund ? 0 : discount
                )}</Text>
            </View>
            <View style={{...spaceBetween}}>
              <Text style={{...bodyText}}>Subtotal: </Text>
              <Text style={{...bodyText}}>{getEuro(
                  isRefund ? -devolucion/1.21 : order.total_price/1.21
                )}</Text>
            </View>
            <View style={{...spaceBetween}}>
              <Text style={{...bodyText}}>IVA (21%): </Text>
              <Text style={{...bodyText}}>{getEuro(
                  isRefund ? -((devolucion+refundSendPrice) - devolucion/1.21)  : order.total_price/1.21*0.21
                )}</Text>
            </View>
            <View style={{...spaceBetween}}>
              <Text style={{...bodyText}}>Total: </Text>
              <Text style={{...bodyText}}>{getEuro(
                  isRefund ? -(devolucion+refundSendPrice) : order.total_price
                )}</Text>
            </View>
          </View>
        </View>
      </View>
    </Page>
  </Document>
  const save = async () => {
    setIsLoading(true)
    const blob = await pdf(bill).toBlob();
    saveAs(blob, `momoi_pedido_${isRefund?`abonado_`:""}${isRefund?order.bill_number:order.refunded_bill_number}_${order.billing.name}`); 
    setIsLoading(false)
  }
  // return <PDFViewer>
  //   {bill}
  // </PDFViewer>

  return <div className="py-4 last:pb-0 first:pt-0">
  <div className="flex items-center h-full">
      <button onClick={save} className={`${isLoading?'animate-pulse text-blue-500':''} h-fit my-auto p-2 transition duration-150 ease-in-out rounded-full hover:bg-neutral-100 dark:hover:bg-neutral-700 focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50`}>
        <div className="flex items-center w-full h-full">
          {!noIcon && <i className="text-3xl las la-download m-auto"></i>}
          {children}
        </div>
      </button>
  </div>
  </div>
}